exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-fr-tsx": () => import("./../../../src/pages/about.fr.tsx" /* webpackChunkName: "component---src-pages-about-fr-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-admin-slack-hr-admin-dashboard-fr-tsx": () => import("./../../../src/pages/admin/slack/hr-admin-dashboard.fr.tsx" /* webpackChunkName: "component---src-pages-admin-slack-hr-admin-dashboard-fr-tsx" */),
  "component---src-pages-admin-slack-hr-admin-dashboard-tsx": () => import("./../../../src/pages/admin/slack/hr-admin-dashboard.tsx" /* webpackChunkName: "component---src-pages-admin-slack-hr-admin-dashboard-tsx" */),
  "component---src-pages-admin-slack-index-fr-tsx": () => import("./../../../src/pages/admin/slack/index.fr.tsx" /* webpackChunkName: "component---src-pages-admin-slack-index-fr-tsx" */),
  "component---src-pages-admin-slack-index-tsx": () => import("./../../../src/pages/admin/slack/index.tsx" /* webpackChunkName: "component---src-pages-admin-slack-index-tsx" */),
  "component---src-pages-admin-slack-install-coachello-app-fr-tsx": () => import("./../../../src/pages/admin/slack/install-coachello-app.fr.tsx" /* webpackChunkName: "component---src-pages-admin-slack-install-coachello-app-fr-tsx" */),
  "component---src-pages-admin-slack-install-coachello-app-tsx": () => import("./../../../src/pages/admin/slack/install-coachello-app.tsx" /* webpackChunkName: "component---src-pages-admin-slack-install-coachello-app-tsx" */),
  "component---src-pages-admin-teams-coachello-to-colleagues-fr-tsx": () => import("./../../../src/pages/admin/teams/coachello-to-colleagues.fr.tsx" /* webpackChunkName: "component---src-pages-admin-teams-coachello-to-colleagues-fr-tsx" */),
  "component---src-pages-admin-teams-coachello-to-colleagues-tsx": () => import("./../../../src/pages/admin/teams/coachello-to-colleagues.tsx" /* webpackChunkName: "component---src-pages-admin-teams-coachello-to-colleagues-tsx" */),
  "component---src-pages-admin-teams-faq-ms-teams-fr-tsx": () => import("./../../../src/pages/admin/teams/faq-ms-teams.fr.tsx" /* webpackChunkName: "component---src-pages-admin-teams-faq-ms-teams-fr-tsx" */),
  "component---src-pages-admin-teams-faq-ms-teams-tsx": () => import("./../../../src/pages/admin/teams/faq-ms-teams.tsx" /* webpackChunkName: "component---src-pages-admin-teams-faq-ms-teams-tsx" */),
  "component---src-pages-admin-teams-how-the-teamsapp-works-fr-tsx": () => import("./../../../src/pages/admin/teams/how-the-teamsapp-works.fr.tsx" /* webpackChunkName: "component---src-pages-admin-teams-how-the-teamsapp-works-fr-tsx" */),
  "component---src-pages-admin-teams-how-the-teamsapp-works-tsx": () => import("./../../../src/pages/admin/teams/how-the-teamsapp-works.tsx" /* webpackChunkName: "component---src-pages-admin-teams-how-the-teamsapp-works-tsx" */),
  "component---src-pages-admin-teams-implementation-for-users-fr-tsx": () => import("./../../../src/pages/admin/teams/implementation-for-users.fr.tsx" /* webpackChunkName: "component---src-pages-admin-teams-implementation-for-users-fr-tsx" */),
  "component---src-pages-admin-teams-implementation-for-users-tsx": () => import("./../../../src/pages/admin/teams/implementation-for-users.tsx" /* webpackChunkName: "component---src-pages-admin-teams-implementation-for-users-tsx" */),
  "component---src-pages-admin-teams-index-fr-tsx": () => import("./../../../src/pages/admin/teams/index.fr.tsx" /* webpackChunkName: "component---src-pages-admin-teams-index-fr-tsx" */),
  "component---src-pages-admin-teams-index-tsx": () => import("./../../../src/pages/admin/teams/index.tsx" /* webpackChunkName: "component---src-pages-admin-teams-index-tsx" */),
  "component---src-pages-admin-teams-microsoft-365-certification-fr-tsx": () => import("./../../../src/pages/admin/teams/microsoft-365-certification.fr.tsx" /* webpackChunkName: "component---src-pages-admin-teams-microsoft-365-certification-fr-tsx" */),
  "component---src-pages-admin-teams-microsoft-365-certification-tsx": () => import("./../../../src/pages/admin/teams/microsoft-365-certification.tsx" /* webpackChunkName: "component---src-pages-admin-teams-microsoft-365-certification-tsx" */),
  "component---src-pages-admin-teams-permissions-scope-fr-tsx": () => import("./../../../src/pages/admin/teams/permissions-&-scope.fr.tsx" /* webpackChunkName: "component---src-pages-admin-teams-permissions-scope-fr-tsx" */),
  "component---src-pages-admin-teams-permissions-scope-tsx": () => import("./../../../src/pages/admin/teams/permissions-&-scope.tsx" /* webpackChunkName: "component---src-pages-admin-teams-permissions-scope-tsx" */),
  "component---src-pages-ai-coaching-fr-tsx": () => import("./../../../src/pages/ai-coaching.fr.tsx" /* webpackChunkName: "component---src-pages-ai-coaching-fr-tsx" */),
  "component---src-pages-ai-coaching-tsx": () => import("./../../../src/pages/ai-coaching.tsx" /* webpackChunkName: "component---src-pages-ai-coaching-tsx" */),
  "component---src-pages-ai-solutions-fr-tsx": () => import("./../../../src/pages/ai-solutions.fr.tsx" /* webpackChunkName: "component---src-pages-ai-solutions-fr-tsx" */),
  "component---src-pages-ai-solutions-tsx": () => import("./../../../src/pages/ai-solutions.tsx" /* webpackChunkName: "component---src-pages-ai-solutions-tsx" */),
  "component---src-pages-case-studies-fr-tsx": () => import("./../../../src/pages/case-studies.fr.tsx" /* webpackChunkName: "component---src-pages-case-studies-fr-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-climate-coaching-tsx": () => import("./../../../src/pages/climate-coaching.tsx" /* webpackChunkName: "component---src-pages-climate-coaching-tsx" */),
  "component---src-pages-coachello-ai-fr-tsx": () => import("./../../../src/pages/coachello-ai.fr.tsx" /* webpackChunkName: "component---src-pages-coachello-ai-fr-tsx" */),
  "component---src-pages-coachello-ai-tsx": () => import("./../../../src/pages/coachello-ai.tsx" /* webpackChunkName: "component---src-pages-coachello-ai-tsx" */),
  "component---src-pages-coaches-fr-tsx": () => import("./../../../src/pages/coaches.fr.tsx" /* webpackChunkName: "component---src-pages-coaches-fr-tsx" */),
  "component---src-pages-coaches-tsx": () => import("./../../../src/pages/coaches.tsx" /* webpackChunkName: "component---src-pages-coaches-tsx" */),
  "component---src-pages-data-processing-addendum-tsx": () => import("./../../../src/pages/data-processing-addendum.tsx" /* webpackChunkName: "component---src-pages-data-processing-addendum-tsx" */),
  "component---src-pages-data-retention-policy-tsx": () => import("./../../../src/pages/data-retention-policy.tsx" /* webpackChunkName: "component---src-pages-data-retention-policy-tsx" */),
  "component---src-pages-executive-coaching-fr-tsx": () => import("./../../../src/pages/executive-coaching.fr.tsx" /* webpackChunkName: "component---src-pages-executive-coaching-fr-tsx" */),
  "component---src-pages-executive-coaching-tsx": () => import("./../../../src/pages/executive-coaching.tsx" /* webpackChunkName: "component---src-pages-executive-coaching-tsx" */),
  "component---src-pages-gorgias-fr-tsx": () => import("./../../../src/pages/gorgias.fr.tsx" /* webpackChunkName: "component---src-pages-gorgias-fr-tsx" */),
  "component---src-pages-gorgias-tsx": () => import("./../../../src/pages/gorgias.tsx" /* webpackChunkName: "component---src-pages-gorgias-tsx" */),
  "component---src-pages-group-workshop-fr-tsx": () => import("./../../../src/pages/group-workshop.fr.tsx" /* webpackChunkName: "component---src-pages-group-workshop-fr-tsx" */),
  "component---src-pages-group-workshop-tsx": () => import("./../../../src/pages/group-workshop.tsx" /* webpackChunkName: "component---src-pages-group-workshop-tsx" */),
  "component---src-pages-im-individual-tsx": () => import("./../../../src/pages/im-individual.tsx" /* webpackChunkName: "component---src-pages-im-individual-tsx" */),
  "component---src-pages-index-fr-tsx": () => import("./../../../src/pages/index.fr.tsx" /* webpackChunkName: "component---src-pages-index-fr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-individual-coaching-fr-tsx": () => import("./../../../src/pages/individual-coaching.fr.tsx" /* webpackChunkName: "component---src-pages-individual-coaching-fr-tsx" */),
  "component---src-pages-individual-coaching-tsx": () => import("./../../../src/pages/individual-coaching.tsx" /* webpackChunkName: "component---src-pages-individual-coaching-tsx" */),
  "component---src-pages-internal-coaching-fr-tsx": () => import("./../../../src/pages/internal-coaching.fr.tsx" /* webpackChunkName: "component---src-pages-internal-coaching-fr-tsx" */),
  "component---src-pages-internal-coaching-tsx": () => import("./../../../src/pages/internal-coaching.tsx" /* webpackChunkName: "component---src-pages-internal-coaching-tsx" */),
  "component---src-pages-leadership-tsx": () => import("./../../../src/pages/leadership.tsx" /* webpackChunkName: "component---src-pages-leadership-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-meilleurs-agents-fr-tsx": () => import("./../../../src/pages/meilleurs-agents.fr.tsx" /* webpackChunkName: "component---src-pages-meilleurs-agents-fr-tsx" */),
  "component---src-pages-meilleurs-agents-tsx": () => import("./../../../src/pages/meilleurs-agents.tsx" /* webpackChunkName: "component---src-pages-meilleurs-agents-tsx" */),
  "component---src-pages-metaverse-tsx": () => import("./../../../src/pages/metaverse.tsx" /* webpackChunkName: "component---src-pages-metaverse-tsx" */),
  "component---src-pages-methodology-fr-tsx": () => import("./../../../src/pages/methodology.fr.tsx" /* webpackChunkName: "component---src-pages-methodology-fr-tsx" */),
  "component---src-pages-methodology-tsx": () => import("./../../../src/pages/methodology.tsx" /* webpackChunkName: "component---src-pages-methodology-tsx" */),
  "component---src-pages-on-demand-tsx": () => import("./../../../src/pages/on-demand.tsx" /* webpackChunkName: "component---src-pages-on-demand-tsx" */),
  "component---src-pages-personal-access-fr-tsx": () => import("./../../../src/pages/personal-access.fr.tsx" /* webpackChunkName: "component---src-pages-personal-access-fr-tsx" */),
  "component---src-pages-personal-access-tsx": () => import("./../../../src/pages/personal-access.tsx" /* webpackChunkName: "component---src-pages-personal-access-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */),
  "component---src-pages-privacy-policy-fr-tsx": () => import("./../../../src/pages/privacy-policy.fr.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-fr-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-talk-to-expert-fr-tsx": () => import("./../../../src/pages/talk-to-expert.fr.tsx" /* webpackChunkName: "component---src-pages-talk-to-expert-fr-tsx" */),
  "component---src-pages-talk-to-expert-tsx": () => import("./../../../src/pages/talk-to-expert.tsx" /* webpackChunkName: "component---src-pages-talk-to-expert-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-thought-leadership-index-fr-tsx": () => import("./../../../src/pages/thought-leadership/index.fr.tsx" /* webpackChunkName: "component---src-pages-thought-leadership-index-fr-tsx" */),
  "component---src-pages-thought-leadership-index-tsx": () => import("./../../../src/pages/thought-leadership/index.tsx" /* webpackChunkName: "component---src-pages-thought-leadership-index-tsx" */),
  "component---src-pages-workshops-tsx": () => import("./../../../src/pages/workshops.tsx" /* webpackChunkName: "component---src-pages-workshops-tsx" */),
  "component---src-templates-blog-post-index-jsx": () => import("./../../../src/templates/BlogPost/index.jsx" /* webpackChunkName: "component---src-templates-blog-post-index-jsx" */)
}

